.spacer {
    height: 10vh;
}

.section {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    flex-direction: column;
}

.section-btn {
    background: none;
    border-radius: var(--border-radius-btn);
    border: 0.1em solid var(--text-color);
    padding: 0.7em 1.4em;
}

.section-btn > a {
    color: var(--text-color);
    padding: 0.7em 0;
    text-decoration: none;
}

.section-btn:hover {
    background: var(--text-color);
}

.section-btn:hover a {
    color: #FFF;
}

@media (max-width: 1024px) {
    .banner-text h1 {
        font-size: 1.7em;
    }
}