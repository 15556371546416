@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    font-size: 10px;
    --main-bg-color: #FFF;
    --text-color: #111;
    --primary-color: #FF8083;
    --hover-color: #F3F6FC;
    --border-radius-btn: 4em;
    --border-radius-nav: 3em;
    --body-font-size: 1.6rem;
    --header-font-size: 1.7em;
    --header-font: Poppins, sans-serif;
}

::selection {
    background: var(--hover-color);
    color: var(--primary-color);
}

h1 {
    font-size: 2em;
    font-family: Poppins, sans-serif;
}

