* {
    font-family: 'Open Sans', sans-serif;
    font-size: var(--body-font-size);
    margin: 0;
    padding: 0;
}

body::-webkit-scrollbar-track {
    background-color: var(--hover-color);
}

body::-webkit-scrollbar
{
    width: 0.7em;
    background-color: #FFF;
}

body::-webkit-scrollbar-thumb
{
    border-radius: var(--border-radius-btn);
    background-color: #E0E0E0;
}

.content-wrapper {
    margin-left: 17vw;
}

.sidebar {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 17vw;
    background-color: var(--main-bg-color);
    filter: drop-shadow(4px 0px 5px #00000030);
    position: fixed;
    left: 0;
    top: 0;
    overflow-y: auto;
    padding-top: 10vh;
}

.toggle-menu {
    display: none;
}

.spacer-lg {
    height: 20vh;
}

.nav-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: inherit;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    width: 3em;
    padding-bottom: 10vh;
}

.nav-link-icon {
    margin-right: 1rem;
}

.nav-list {
    width: 10vw;
    margin: 0.2em 0;
}

.nav-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--text-color);
    padding: 1em 2em;
}

.nav-list:last-child {
    margin: 20vh 0 0 0;
    display: inline-flex;
    justify-content: center;
}

.nav-list:last-child .nav-link{
    text-align: center;
    width: fit-content;
    font-size: 0.8em;
    background: var(--text-color);
    color: var(--main-bg-color);
    padding: 0.8rem;
    margin-left: 0;
    border-radius: var(--border-radius-btn);
}

.nav-link:hover {
    background-color: var(--hover-color);
    border-radius: var(--border-radius-nav);
}

.nav-list:has(.active) {
    background-color: var(--hover-color);
    border-radius: var(--border-radius-nav);
}

.active{
    color: var(--primary-color);
}

.btn-wrapper {
    margin-top: 5vh;
}

.btn-1 {
    background: #FFF;
    border-radius: var(--border-radius-btn);
    border: 0.1em solid #FFF;
    padding: 0.7em 1.4em;
}

.btn-1 > a{
    color: var(--text-color);
    text-decoration: none;
    padding: 0.7em 0;
}

.btn-1:hover {
    border: 0.1em solid #FFF;
    background: none;
}

.btn-1:hover > a {
    color: #FFF;
}

.btn-2 {
    background: none;
    border-radius: var(--border-radius-btn);
    border: 0.1em solid #FFF;
    margin-left: 1.4em;
    padding: 0.7em 1.4em;
}

.btn-2 > a {
    color: #FFF;
    padding: 0.7em 0;
    text-decoration: none;
}

.btn-2:hover {
    background: #FFF;
}

.btn-2:hover a {
    color: var(--text-color);
}

@media (max-width: 1440px) {
    .nav-link {
        font-size: 1.2em;
    }
}
@media (max-width: 1024px) {
    .sidebar {
        width: 23vw;
    }

    .nav-list {
        width: 14vw;
    }

    .content-wrapper {
        margin-left: 23vw;
    }

}

@media (max-width: 768px) {
    @keyframes slideIn {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(0%);
        }
    }
    .content-wrapper {
        width: 100%;
        margin-left: 0;
    }

    .sidebar {
        filter: none;
        position: sticky;
        flex-direction: row;
        height: auto;
        background: var(--hover-color);
        width: 100%;
        padding-top: 0;
        align-items: flex-start;
        z-index: 999;
    }

    .sidebar.show .nav-menu {
        display: flex;
        background: var(--hover-color);
        width: 50%;
        height: 100vh;
        position: fixed;
        padding-top: 15vh;
        align-items: flex-start;
        animation: slideIn 1s;
    }

    .nav-list {
        width: 100%;
    }

    .nav-list:last-child {
        justify-content: flex-start;
    }

    .nav-list:has(.active) {
        background: var(--main-bg-color);
        width: fit-content;
    }

    .nav-link:hover {
        background: none;
    }

    .nav-list:last-child .nav-link {
        margin-left: 2em;
    }

    .logo {
        height: 9vh;
        margin-top: 0.6em;
        padding-bottom: 4vh;
    }

    .nav-menu {
        display: none;
    }

    .toggle-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 3em;
        background: none;
        border: none;
    }

    .show {
        flex-direction: column;
    }

    .banner-wrapper {
        display: inline-flex;
        width: 100%;
        background: var(--hover-color);
        padding-top: 5vh;
        z-index: 9999;
    }

    .menu {
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        padding: 0;
    }

    .btn-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    }

    .btn-2 {
        margin-top: 0;
        margin-left: 0.7em;
    }

    /* Close and open burger icon */
    .line {
        fill: none;
        stroke: black;
        stroke-width: 6;
        transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .line1 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
    }

    .line2 {
        stroke-dasharray: 60 60;
        stroke-width: 6;
    }

    .line3 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
    }

    .opened .line1 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
    }

    .opened .line2 {
        stroke-dasharray: 1 60;
        stroke-dashoffset: -30;
        stroke-width: 6;
    }

    .opened .line3 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
    }

}

@media (max-width: 426px) {
    .banner-wrapper {
        padding-top: 3vh;
    }

    .btn-1 {
        padding: 0.6em 1em;
    }

    .btn-2 {
        padding: 0.6em 1em;
    }

    .btn-1 > a {
        font-size: 0.9em;
    }

    .btn-2 > a {
        font-size: 0.9em;
    }
}