.banner-bg {
    background-repeat: no-repeat;
    background-size: cover;
    width: 70%;
    border-radius: 2em;
    margin: 0 auto;
    text-align: center;
    color: #FFF;
    padding: 4em 0;
}

.banner-heading {
    margin-bottom: 0.4em;
}

.banner-subtitle {
    font-size: 1.2em;
    font-weight: 300;
    margin-bottom: 1.1em;
}


@media (max-width: 1024px){
    .banner-bg {
        width: 100%;
        border-radius: 0;
    }
}

@media (max-width: 426px) {
    .banner-heading {
        font-size: 1.2em;
    }

    .banner-subtitle {
        font-size: 1em;
    }
}