.card-wrapper {
    display: grid;
    padding: 0;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 4em;
    justify-items: center;
    margin-right: 3em;
}

@media (max-width: 1240px){
    .card-wrapper {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 1em;
    }
}

@media (max-width: 426px) {
    .card-wrapper {
        grid-template-columns: repeat(1, 1fr);
        margin-right: 0;
    }
}