.btn-blog {
    padding: 10px;
    border: 2px solid var(--primary-color);
    background-color: var(--primary-color);
    color: #FFF;
    font-weight: 500;
    border-radius: var(--border-radius-btn);
    width: fit-content;
    margin: 2rem 0;
}

.enabled\:hover\:bg-cyan-800:hover:enabled {
    background: none;
    color: var(--text-color);
}