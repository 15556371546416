.quotes {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
}

.quotes h1 {
    width: 30vw;
    margin-bottom: 0.7em;
    font-weight: 700;
}

.quotes-heading {
    width: 35vw;
    font-size: 2em;
    margin-bottom: 0.7em;
    text-align: left;
    font-weight: 700;
}

.quotes-subtitle {
    width: 35vw;
    margin-bottom: 1.4em;
    font-size: 1em;
    text-align: left;
    font-weight: 400;
    font-family: var(--header-font);
}

.opacity-text {
    font-size: inherit;
    opacity: 0.3;
}

@media (max-width: 1024px) {
    .quotes h1 {
        font-size: 1.7em;
        width: 50vw;
    }

    .quotes p {
        font-size: 0.9em;
        margin: 0 4em;
        width: fit-content;
    }

    .quotes-heading {
        font-size: 1.7em;
        width: 50vw;
    }

    .quotes-subtitle {
        font-size: 0.9em;
        margin-bottom: 3em;
    }
}


@media (max-width: 768px) {
    .quotes h1 {
        width: 50vw;
    }
}