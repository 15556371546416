.work-projects-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 2em;
    grid-row-gap: 3em;
    margin: 3em;
}


@media (max-width: 768px) {
    .work-projects-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 426px) {
    .work-projects-wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
}
