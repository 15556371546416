* {
    font-family: var(--header-font);
}

.text-2xl {
    font-size: 2rem;
    margin: 2rem 0;
    font-family: var(--header-font);
}

.max-w-sm {
    max-width: 40rem;
}

.p-0\.5 {
    margin: 1rem 0;
    width: 50%;
}

.btn-project {
    padding: 10px 4px;
    border: 2px solid var(--primary-color);
    background: none;
    color: var(--text-color);
    border-radius: var(--border-radius-btn);
}

.enabled\:hover\:bg-cyan-800:hover:enabled {
    background-color: var(--primary-color);
    color: #FFF;
}