.banner-background {
    height: 100vh;
    overflow: hidden;
    z-index: -1;
    background-color: #ffffff;
    background-image: radial-gradient(at 64% 84%, hsl(360, 100%, 75%) 0, hsla(12, 90%, 58%, 0) 50%),
    radial-gradient(at 28% 60%, hsla(314, 95%, 64%, 1) 0, hsla(314, 95%, 64%, 0) 50%),
    radial-gradient(at 90% 26%, hsla(274, 86%, 64%, 1) 0, hsla(274, 86%, 64%, 0) 50%),
    radial-gradient(at 6% 29%, hsl(330, 55%, 61%) 0, hsla(88, 94%, 61%, 0) 50%),
    radial-gradient(at 69% 13%, hsla(345, 92%, 63%, 1) 0, hsla(345, 92%, 63%, 0) 50%),
    radial-gradient(at 35% 64%, hsl(0, 100%, 75%) 0, hsla(119, 94%, 68%, 0) 50%),
    radial-gradient(at 34% 91%, hsla(15, 93%, 52%, 1) 0, hsla(15, 93%, 52%, 0) 50%);
    border: 3vw solid white;
    border-radius: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-content {
    width: 40vw;
    display: flex;
    padding: 3em;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2em;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}


.profile-picture {
    height: 18vh;
    margin-right: 3em;
    border: 0.3em solid white;
    border-radius: 7em;
}

.banner-text {
    color: #FFF;
}

.banner-text h1 {
    font-size: 2em;
}

.banner-text span {
    font-weight: 300;
    font-size: 1.2em;
}

@media (max-width: 1024px) {
    .banner-background {
        border: 2vw solid #FFF;
        height: 94vh;
        border-radius: 3em;
    }

    .banner-text span {
        font-size: 1.2em;
    }

    .banner-content {
        flex-direction: column;
    }

    .btn-wrapper {
        flex-direction: row;
    }

    .profile-picture {
        height: 15vh;
        width: 11vw;
        margin-bottom: 1em;
    }

    .btn-2 {
        margin-top: 0;
    }
}

@media (max-width: 768px) {
    .banner-background {
        height: 80vh;
        border-radius: 3em;
    }

    .banner-content {
        flex-direction: column;
        align-items: center;
        width: 55vw;
    }

    .banner-text {
        text-align: center;
    }

    .profile-picture {
        width: 13vw;
        height: auto;
        margin-bottom: 1em;
        margin-right: 0;
    }
}

@media (max-width: 426px) {
    .banner-background {
        height: 84vh;
    }

    .banner-content {
        width: 70vw;
    }

    .banner-text h1 {
        font-size: 1.8em;
    }

    .profile-picture {
        width: 24vw;
    }

    .banner-text span {
        font-size: 1.1em;
    }
}