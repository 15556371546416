.ui-card {
    background: #FFF;
    padding: 3em 3.4em 4em 3.4em;
    border-radius: 2em;
    margin: 0 0 0 3em;
    min-width: 20vw;
    filter: drop-shadow(3px 7px 7px #00000030);
}

.ui-card-image {
    width: 3.4em;
    margin-bottom: 1.5em;
    height: 3.4em;
}

.ui-card-title {
    font-size: 1.3em;
    font-weight: 700;
    font-family: var(--header-font);
}

.ui-card-content {
    display: flex;
    flex-direction: column;
}

.ui-card-description {
    margin-top: 0.7em;
}

.ui-card-level::before {
    font-weight: 800;
    content: 'Level: ';
}

.ui-card-education::before {
    font-weight: 800;
    content: 'Status: ';
}

.ui-card-years::before {
    font-weight: 800;
    content: 'Years: ';
}


@media (max-width: 1240px) {
    .ui-card {
        width: 90%;
        padding: 2em 2.4em 2.4em 2.4em;
        display: flex;
        margin-bottom: 3em;
    }

    .ui-card-image {
        margin-right: 2.4em;
    }

    .ui-card-title {
        font-size: 1.1em;
    }

    .ui-card-description {
        font-size: 0.9em;
    }
}

@media (max-width: 910px) {
    .ui-card {
        width: 88%;
    }
}

@media (max-width: 768px) {
    .ui-card {
        width: 90%;
        padding: 2em 2.4em 2.4em 2.4em;
        display: flex;
        margin-bottom: 3em;
    }
}

@media (max-width: 426px) {
    .ui-card {
        width: 80%;
        flex-direction: column;
        margin: 0 1em 1em 1em;
    }

    .ui-card-image {
        width: 3em;
        height: 3em;
    }
}