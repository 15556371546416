.footer {
    display: flex;
    justify-content: center;
    margin-left: 17vw;
    width: inherit;
    padding: 1em 0;
    background-color: var(--hover-color);
    font-size: 0.8em;
}

@media (max-width: 768px) {
    .footer {
        margin-left: 0;
    }
}